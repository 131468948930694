function Slider(props) {
    var before = "./before/" + props.name + "_aligned.jpg";
    var after = "./after/" + props.name + "_aligned.jpg";
    var imgClass = "img_" + props.orientation;
    var divClass = "slider slider_" + props.orientation
    return (
        <div className={divClass} id={props.name}>
            <img-comparison-slider>
                <img slot="first" src={before} className={imgClass} alt="Before" />
                <img slot="second" src={after} className={imgClass} alt="After" />
            </img-comparison-slider>
        </div>
    );
}

export default Slider;
import './App.css';
import Slider from './Slider';

function App() {
    return (
        <div className="app">
            <h1>Kitchen and Family Room</h1>
            <Slider name="family_room_kitchen" orientation="landscape" />
            <h1>Family Room</h1>
            <Slider name="family_room" orientation="landscape" />
            <h1>Kitchen</h1>
            <Slider name="kitchen" orientation="landscape" />
            <h1>Foyer</h1>
            <Slider name="foyer" orientation="portrait" />
            <h1>Master Bathroom</h1>
            <Slider name="master_bath_1" orientation="portrait" />
            <Slider name="master_bath_2" orientation="portrait" />
        </div>
    );
}

export default App;